import { Reducer } from "redux";
import { actions as ActionTypes } from "../../../constants/action";
import { UserStore, UserActions } from "../../../types/user";

const initialState: UserStore = {
  token: "",
  error: false,
  tokenExpired: false,
  currentActionKey: "",
  tokenRetryAttempt: 0,
};

export const userReducer: Reducer<UserStore, UserActions> = (
  state: UserStore = initialState,
  action: UserActions = { type: ActionTypes.UNDEFINED }
): UserStore => {
  switch (action.type) {
    case ActionTypes.FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
        tokenExpired: false,
        tokenRetryAttempt: 0,
        error: false,
      };
    case ActionTypes.REFRESH_TOKEN_REQUESTED:
      return {
        ...state,
        tokenExpired: true,
        currentActionKey: action.currentActionKey,
      };
    case ActionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
        tokenExpired: false,
        currentActionKey: "",
        tokenRetryAttempt: state.tokenRetryAttempt + 1,
        error: false,
      };
    case ActionTypes.RESET_REFRESH_TOKEN:
      return {
        ...state,
        tokenExpired: false,
        currentActionKey: "",
        error: true,
      };
    default:
      return state;
  }
};
