// colors
const deepblue = "#0E4165";
const midblue = "#237DBA";
const lightblue = "#E2F6FD";
const oceanblue = "#249CB6";
const lightred = "#EE2E2E";
const formatred = "#C34E42";
const brown = "#DFC5A9";

// font
const smallFontSize = "1.4rem";
const smallHeaderSize = "1.6rem";

export const theme2 = {
    palette: {
      primary: {
          main: `${lightred}`,
      },
      secondary: {
          main: `${brown}`,
      },
    },
    shape: {
        borderRadius: 4
    },
    components: {
        MuiButton: {
            defaultProps: {
              sx: {
                  margin: 1
              }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    background: "white"
                }
            }
        }
    }
  }