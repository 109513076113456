import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Badge, { BadgeProps } from "@mui/material/Badge";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AddIcon from "@mui/icons-material/Add";

export type OwnProps = {
  title?: string;
  thumbsUpNo?: number;
  thumbsUpHandler?: () => void;
  content?: string;
  backgroundColor: string;
  addNewItem?: () => void;
};

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -5,
    top: 1,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const SprintRetroCard: React.FC<OwnProps> = ({
  title,
  content,
  backgroundColor,
  thumbsUpNo = 0,
  thumbsUpHandler,
  addNewItem,
}) => {
  return (
    <Card sx={{ minWidth: 275, backgroundColor: backgroundColor }}>
      <CardContent>
        {title && (
          <Typography variant="h5" component="div" sx={{ color: "white" }}>
            {title}
            <IconButton
              aria-label="add new item"
              sx={{ marginLeft: "1px" }}
              onClick={addNewItem}
            >
              <AddIcon />
            </IconButton>
          </Typography>
        )}
        {content && (
          <Typography variant="body2" sx={{ backgroundColor: "white" }}>
            {content}
          </Typography>
        )}
      </CardContent>
      {content && (
        <CardActions>
          <IconButton
            aria-label="add to agree this idea"
            sx={{ marginLeft: "auto" }}
            onClick={thumbsUpHandler}
          >
            <StyledBadge badgeContent={thumbsUpNo} color="primary">
              <ThumbUpIcon />
            </StyledBadge>
          </IconButton>
        </CardActions>
      )}
    </Card>
  );
};

export default SprintRetroCard;
