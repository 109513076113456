import { actions as ActionTypes } from "../../../constants/action";
import { FetchTokenSuccessAction, RefreshTokenSuccessAction, RefreshTokenRequestedAction, RefreshTokenResetAction } from "../../../types/user";

export const fetchTokenSuccessAction: (token: string) => FetchTokenSuccessAction = (token) => {
    return {
        type: ActionTypes.FETCH_TOKEN_SUCCESS,
        token,
    };
};

export const refreshTokenRequestedAction: (currentActionKey: string) => RefreshTokenRequestedAction = (currentActionKey) => {
    return {
        type: ActionTypes.REFRESH_TOKEN_REQUESTED,
        currentActionKey,
    };
};

export const refreshTokenSuccessAction: (token: string) => RefreshTokenSuccessAction = (token) => {
    return {
        type: ActionTypes.REFRESH_TOKEN_SUCCESS,
        token,
    };
};

export const refreshTokenResetAction: () => RefreshTokenResetAction = () => {
    return {
        type: ActionTypes.RESET_REFRESH_TOKEN,
    };
};
