import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import WheelDecider from "../../molecules/WheelDecider";
import SprintRetroBoard from "../../organisms/SprintRetroBoard";
import Wiki from "../../organisms/Wiki";

const AppNavWrapper: React.FC = () => {
  const theme = useTheme();
  const isLargerScreen = useMediaQuery(theme.breakpoints.up("sm")); // 600px or not
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentPageIndex(newValue);
  };

  return (
    <div>
      <Tabs
        value={currentPageIndex}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="fullWidth"
        aria-label="secondary tabs example"
      >
        <Tab value={0} label="Lucky Wheel" />
        <Tab value={1} label="Retrospective" />
        <Tab value={2} label="Wiki" />
      </Tabs>
      <Box
        sx={{
          marginTop: "10px",
        }}
      >
        {currentPageIndex === 0 && <WheelDecider />}
        {currentPageIndex === 1 && <SprintRetroBoard />}
        {currentPageIndex === 2 && <Wiki />}
      </Box>
    </div>
  );
};
export default AppNavWrapper;
