import { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import SprintRetroCard from "../../molecules/SprintRetroCard";
import AddRetroItemDialog from "../../molecules/AddRetroItemDialog";
import { RetroItemType, RetroResult } from "../../../types/sprint";
import retroRecordMock from "../../../mock/retroRecordMock.json";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff", // TODO: add dark theme feature toggle
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function SprintRetroBoard() {
  const [retroResult, setRetroResult] = useState<RetroResult>(retroRecordMock);
  const [newItemValue, setNewItemValue] = useState<string>("");
  const [currentNewItemType, setCurrentNewItemType] =
    useState<RetroItemType | null>(null);
  const [isNewItemDialogOpen, setIsNewItemDialogOpen] =
    useState<boolean>(false);

  const thumbsUpHandler = (index: number, groupType: RetroItemType) => {
    let value: RetroResult;
    if (groupType === "Worked Well") {
      value = {
        ...retroResult,
        workedWell: retroResult.workedWell.map((i, id) => {
          if (id === index) {
            return {
              ...i,
              supportNo: i.supportNo + 1,
            };
          } else {
            return i;
          }
        }),
      };
      setRetroResult(value);
    }
    if (groupType === "Needs Improvement") {
      value = {
        ...retroResult,
        needsImprovement: retroResult.needsImprovement.map((i, id) => {
          if (id === index) {
            return {
              ...i,
              supportNo: i.supportNo + 1,
            };
          } else {
            return i;
          }
        }),
      };
      setRetroResult(value);
    }
    if (groupType === "New Ideas") {
      value = {
        ...retroResult,
        newIdeas: retroResult.newIdeas.map((i, id) => {
          if (id === index) {
            return {
              ...i,
              supportNo: i.supportNo + 1,
            };
          } else {
            return i;
          }
        }),
      };
      setRetroResult(value);
    }
    if (groupType === "Recognition") {
      value = {
        ...retroResult,
        recognition: retroResult.recognition.map((i, id) => {
          if (id === index) {
            return {
              ...i,
              supportNo: i.supportNo + 1,
            };
          } else {
            return i;
          }
        }),
      };
      setRetroResult(value);
    }
  };

  const closeNewItemDialog = () => {
    setIsNewItemDialogOpen(false);
    // cleanup dialog input
    setNewItemValue("");
  };

  const saveNewItem = () => {
    // save
    let value: RetroResult;
    if (currentNewItemType === "Worked Well") {
      value = {
        ...retroResult,
        workedWell: [
          ...retroResult.workedWell,
          {
            content: newItemValue,
            supportNo: 0,
          },
        ],
      };
      setRetroResult(value);
    }
    if (currentNewItemType === "Needs Improvement") {
      value = {
        ...retroResult,
        needsImprovement: [
          ...retroResult.needsImprovement,
          {
            content: newItemValue,
            supportNo: 0,
          },
        ],
      };
      setRetroResult(value);
    }
    if (currentNewItemType === "New Ideas") {
      value = {
        ...retroResult,
        newIdeas: [
          ...retroResult.newIdeas,
          {
            content: newItemValue,
            supportNo: 0,
          },
        ],
      };
      setRetroResult(value);
    }
    if (currentNewItemType === "Recognition") {
      value = {
        ...retroResult,
        recognition: [
          ...retroResult.recognition,
          {
            content: newItemValue,
            supportNo: 0,
          },
        ],
      };
      setRetroResult(value);
    }
    closeNewItemDialog();
  };

  const addNewItemHandler = (itemType: RetroItemType) => {
    setCurrentNewItemType(itemType);
    setIsNewItemDialogOpen(true);
  };

  const newItemChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewItemValue(event.target.value);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          // sx={{
          //   "--Grid-borderWidth": "1px",
          //   borderTop: "var(--Grid-borderWidth) solid",
          //   borderLeft: "var(--Grid-borderWidth) solid",
          //   borderColor: "divider",
          //   "& > div": {
          //     borderRight: "var(--Grid-borderWidth) solid",
          //     borderBottom: "var(--Grid-borderWidth) solid",
          //     borderColor: "divider",
          //   },
          // }}
        >
          <Grid xs={12} sm={6} md={6}>
            <Item>
              <SprintRetroCard
                title="Worked Well"
                backgroundColor="#6ACF9D"
                addNewItem={() => addNewItemHandler("Worked Well")}
              />
              {retroResult.workedWell.map((i, index) => {
                return (
                  <SprintRetroCard
                    key={i.content + index}
                    content={i.content}
                    backgroundColor="#6ACF9D"
                    thumbsUpNo={i.supportNo}
                    thumbsUpHandler={() =>
                      thumbsUpHandler(index, "Worked Well")
                    }
                  />
                );
              })}
            </Item>
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            <Item>
              <SprintRetroCard
                title="Needs Improvement"
                backgroundColor="#F3BE49"
                addNewItem={() => addNewItemHandler("Needs Improvement")}
              />
              {retroResult.needsImprovement.map((i, index) => {
                return (
                  <SprintRetroCard
                    key={i.content + index}
                    content={i.content}
                    backgroundColor="#F3BE49"
                    thumbsUpNo={i.supportNo}
                    thumbsUpHandler={() =>
                      thumbsUpHandler(index, "Needs Improvement")
                    }
                  />
                );
              })}
            </Item>
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            <Item>
              <SprintRetroCard
                title="New Ideas"
                backgroundColor="#DC34B2"
                addNewItem={() => addNewItemHandler("New Ideas")}
              />
              {retroResult.newIdeas.map((i, index) => {
                return (
                  <SprintRetroCard
                    key={i.content + index}
                    content={i.content}
                    backgroundColor="#DC34B2"
                    thumbsUpNo={i.supportNo}
                    thumbsUpHandler={() => thumbsUpHandler(index, "New Ideas")}
                  />
                );
              })}
            </Item>
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            <Item>
              <SprintRetroCard
                title="Recognition"
                backgroundColor="#4256CE"
                addNewItem={() => addNewItemHandler("Recognition")}
              />
              {retroResult.recognition.map((i, index) => {
                return (
                  <SprintRetroCard
                    key={i.content + index}
                    content={i.content}
                    backgroundColor="#4256CE"
                    thumbsUpNo={i.supportNo}
                    thumbsUpHandler={() =>
                      thumbsUpHandler(index, "Recognition")
                    }
                  />
                );
              })}
            </Item>
          </Grid>
        </Grid>
      </Box>
      <AddRetroItemDialog
        isOpen={isNewItemDialogOpen}
        closeDialog={closeNewItemDialog}
        saveNewItem={saveNewItem}
        type={currentNewItemType as RetroItemType}
        newItemValue={newItemValue}
        newItemChangeHandler={newItemChangeHandler}
      />
    </>
  );
}
