import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import WikiCard from "../../molecules/WikiCard";

const Wiki: React.FC = () => {
  return (
    <>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="search-wiki-card-input"
          label="Search"
          variant="outlined"
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <WikiCard />
        </Grid>
        <Grid item xs={12} md={3}>
          <WikiCard />
        </Grid>
        <Grid item xs={12} md={3}>
          <WikiCard />
        </Grid>
        <Grid item xs={12} md={3}>
          <WikiCard />
        </Grid>
        <Grid item xs={12} md={3}>
          <WikiCard />
        </Grid>
      </Grid>
      <Stack spacing={2}>
        <Pagination count={10} color="secondary" />
      </Stack>
    </>
  );
};

export default Wiki;
