import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { userActions } from "./store/actions";
import { Store } from "./types/common/store";
import CssBaseline from "@mui/material/CssBaseline";
// import TaskMgtContainer from "./screens/TaskMgt";
import ProductsMgt from "./components/ProductsMgt";
import Category from "./components/Category";
import StepAppWrapper from "./components/pages/AppNavWrapper";
import { baseTheme1, baseTheme2 } from "../src/utils/theme";
import { fetchToken } from "./api/user/fetchToken";

import "./App.css";

function App() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user: userState } = useSelector((reduxstore: Store) => reduxstore);
  // TODO: switch theme with user preference and btn clicks, at least 3 themes
  const [theme, setTheme] = useState(baseTheme1);
  const { data, isLoading, error } = useQuery(["token"], fetchToken, {
    cacheTime: Infinity,
    retry: false,
    onError: () =>
      console.log(
        "error toast message for fail fetch token, UI access should be stopped"
      ),
    onSuccess: (data: any) => {
      dispatch(userActions.fetchTokenSuccessAction(data.data.token));
    },
  });

  const refreshTokenResult = useQuery(["refresh-token"], fetchToken, {
    cacheTime: Infinity,
    retry: false,
    onError: () =>
      console.log(
        "error toast message for fail fetch token, UI access should be stopped"
      ),
    onSuccess: (data: any) => {
      const currentRequestKey = userState.currentActionKey;
      // clean up store and add refresh token retry + 1
      dispatch(userActions.refreshTokenSuccessAction(data.data.token));
      console.log("products");
      // queryClient.invalidateQueries(['products']);
    },
    enabled: userState.tokenExpired && userState.tokenRetryAttempt < 4, // only retry 3 times max..
  });

  useEffect(() => {
    if (userState.tokenRetryAttempt < 4) {
      queryClient.invalidateQueries(["products"]).then(() => {});
    }
  }, [userState.tokenRetryAttempt]);

  if (isLoading) return <>is loading....</>;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {/* <TaskMgtContainer /> */}
        {/* <ProductsMgt />
        <Category /> */}
        <StepAppWrapper />
      </div>
    </ThemeProvider>
  );
}

export default App;
