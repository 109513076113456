import { useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { RetroItemType } from "../../../types/sprint";

export type AddRetroItemDialogProps = {
  isOpen: boolean;
  closeDialog: () => void;
  saveNewItem: () => void;
  type: RetroItemType;
  newItemChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  newItemValue: string;
};

const AddRetroItemDialog: React.FC<AddRetroItemDialogProps> = ({
    isOpen,
    closeDialog,
    saveNewItem,
    type,
    newItemChangeHandler,
    newItemValue,
}) => {

  return (
    <>
      {/* TODO: change to mobile version dialog once in mobile screen */}
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Add New Records for {type}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="newRetroItem"
            fullWidth
            multiline
            maxRows={5}
            variant="standard"
            value={newItemValue}
            onChange={newItemChangeHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={saveNewItem}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddRetroItemDialog;
