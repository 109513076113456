// TODO: canvas not supports in IE, added notifications in the page for IE users!!!
import { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { randomeColorGenerator } from "../../../utils/common";
import EditableTextField from "../../atoms/EditableTextField";
import WinnerCard from "../WinnerCard";
import styles from "./index.module.css";

const WheelDecider = () => {
  // size calculate
  // TODO: move to size configure useState, based on 500 full canvas size..
  const outsideRadius = 200;
  const insideRadius = 125;
  const textRadius = 160;

  const canvasRef = useRef(null);

  const [colorList, setColorList] = useState<Array<string>>([]);
  const [teamMembers, setTeamMembers] = useState<Array<string>>(
    localStorage.getItem("wheeldecider") !== null
      ? JSON.parse(localStorage.getItem("wheeldecider") as any)
      : ["Anbu", "Samy", "Sankalp", "Tim", "Andrew"]
  );
  const [newMember, setNewMember] = useState<string>("");
  const [winners, setWinners] = useState<Array<string>>([]);

  // // init winner from localstorage
  useEffect(() => {
    const existingWinners = localStorage.getItem("winners");
    if (existingWinners) {
      setWinners(JSON.parse(existingWinners));
    }
  }, []);

  useEffect(() => {
    console.log("teamMembers length changed", teamMembers);
    if (teamMembers.length > 1) {
      const arrayOfColors = [];
      for (let i = 0; i < teamMembers.length; i += 1) {
        arrayOfColors.push(randomeColorGenerator());
      }
      setColorList(arrayOfColors);
    }
  }, [teamMembers.length]);

  useEffect(() => {
    if (colorList.length > 1) {
      drawWheel();
    }
  }, [colorList.length]);

  const cleanWinner = () => {
    setWinners([]);
  };

  // arc: each slice how many degree
  const [arc, setArc] = useState<number>(Math.PI / (teamMembers.length / 2));
  const spinAngleStart = useRef(0);
  const spinTimeTotal = useRef(0);
  const spinTime = useRef(0);
  const startAngle = useRef(0);
  const spinTimeout = useRef<any>(null);

  const startSpin = () => {
    spinAngleStart.current = Math.random() * 10 + 10;
    spinTime.current = 0;
    spinTimeTotal.current = Math.random() * 3000 + 4 * 1000; // randome seconds to spin
    rotateWheel();
  };

  const drawWheel = () => {
    const canvas = canvasRef.current;
    const ctx = (canvas as any).getContext("2d");
    ctx.clearRect(0, 0, 500, 500);
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.font = "bold 12px Helvetica, Arial";

    for (let i = 0; i < teamMembers.length; i += 1) {
      const angle = startAngle.current + i * arc;
      const randomColor = colorList[i];
      ctx.fillStyle = randomColor;

      ctx.beginPath();
      ctx.arc(250, 250, outsideRadius, angle, angle + arc, false);
      ctx.arc(250, 250, insideRadius, angle + arc, angle, true);
      ctx.stroke();
      ctx.fill();

      // draw text
      ctx.save();
      ctx.shadowOffsetX = -1;
      ctx.shadowOffsetY = -1;
      ctx.shadowBlur = 0;
      ctx.shadowColor = "rgb(220,220,220)";
      ctx.fillStyle = "black";
      ctx.translate(
        250 + Math.cos(angle + arc / 2) * textRadius,
        250 + Math.sin(angle + arc / 2) * textRadius
      );
      ctx.rotate(angle + arc / 2 + Math.PI / 2);
      const text = teamMembers[i];
      ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
      ctx.restore();
    }

    //Arrow spinner
    ctx.fillStyle = "black";
    ctx.beginPath();
    ctx.moveTo(250 - 4, 250 - (outsideRadius + 5));
    ctx.lineTo(250 + 4, 250 - (outsideRadius + 5));
    ctx.lineTo(250 + 4, 250 - (outsideRadius - 5));
    ctx.lineTo(250 + 9, 250 - (outsideRadius - 5));
    ctx.lineTo(250 + 0, 250 - (outsideRadius - 13));
    ctx.lineTo(250 - 9, 250 - (outsideRadius - 5));
    ctx.lineTo(250 - 4, 250 - (outsideRadius - 5));
    ctx.lineTo(250 - 4, 250 - (outsideRadius + 5));
    ctx.fill();
  };

  const drawWheelWithArg = (newteamMembers: Array<string>) => {
    const canvas = canvasRef.current;
    const ctx = (canvas as any).getContext("2d");
    ctx.clearRect(0, 0, 500, 500);
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.font = "bold 12px Helvetica, Arial";

    for (let i = 0; i < newteamMembers.length; i += 1) {
      const angle = startAngle.current + i * arc;
      const randomColor = colorList[i];
      ctx.fillStyle = randomColor;

      ctx.beginPath();
      ctx.arc(250, 250, outsideRadius, angle, angle + arc, false);
      ctx.arc(250, 250, insideRadius, angle + arc, angle, true);
      ctx.stroke();
      ctx.fill();

      // draw text
      ctx.save();
      ctx.shadowOffsetX = -1;
      ctx.shadowOffsetY = -1;
      ctx.shadowBlur = 0;
      ctx.shadowColor = "rgb(220,220,220)";
      ctx.fillStyle = "black";
      ctx.translate(
        250 + Math.cos(angle + arc / 2) * textRadius,
        250 + Math.sin(angle + arc / 2) * textRadius
      );
      ctx.rotate(angle + arc / 2 + Math.PI / 2);
      const text = newteamMembers[i];
      ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
      ctx.restore();
    }

    //Arrow spinner
    ctx.fillStyle = "black";
    ctx.beginPath();
    ctx.moveTo(250 - 4, 250 - (outsideRadius + 5));
    ctx.lineTo(250 + 4, 250 - (outsideRadius + 5));
    ctx.lineTo(250 + 4, 250 - (outsideRadius - 5));
    ctx.lineTo(250 + 9, 250 - (outsideRadius - 5));
    ctx.lineTo(250 + 0, 250 - (outsideRadius - 13));
    ctx.lineTo(250 - 9, 250 - (outsideRadius - 5));
    ctx.lineTo(250 - 4, 250 - (outsideRadius - 5));
    ctx.lineTo(250 - 4, 250 - (outsideRadius + 5));
    ctx.fill();
  };

  const stopRotateWheel = () => {
    clearTimeout(spinTimeout.current);
    const degrees = (startAngle.current * 180) / Math.PI + 90;
    // make sure use PI to calculate degree! instead a direct number
    const arcd = (arc * 180) / Math.PI;
    const index = Math.floor((360 - (degrees % 360)) / arcd);
    const canvas = canvasRef.current;
    const ctx = (canvas as any).getContext("2d");
    ctx.save();
    ctx.font = "bold 30px Helvetica, Arial";
    const text = teamMembers[index];
    console.log("winner is", text);
    // const newArray = winners, newArray.push() then setState will not work!!! cause no rerender
    const currentWinners = winners.concat([text]);
    setWinners(currentWinners);
    // update local storage
    localStorage.setItem("winners", JSON.stringify(currentWinners));
    // draw name in center
    ctx.fillText(text, 250 - ctx.measureText(text).width / 2, 250 + 10);
    ctx.restore();
  };

  const easeOut = (t: any, b: any, c: any, d: any) => {
    const ts = (t /= d) * t;
    const tc = ts * t;
    return b + c * (tc + -3 * ts + 3 * t);
  };

  const rotateWheel = () => {
    spinTime.current += 30;
    if (spinTime.current >= spinTimeTotal.current) {
      stopRotateWheel();
      return;
    }
    const spinAngle =
      spinAngleStart.current -
      easeOut(
        spinTime.current,
        0,
        spinAngleStart.current,
        spinTimeTotal.current
      );
    startAngle.current += (spinAngle * Math.PI) / 180;
    drawWheel();
    spinTimeout.current = setTimeout(rotateWheel, 30);
  };

  const updateLocalStorage = (newTeams: Array<string>) => {
    localStorage.setItem("wheeldecider", JSON.stringify(newTeams));
  };

  const removeHandler = (index: number) => {
    const result = teamMembers;
    result.splice(index, 1);
    setTeamMembers(result);
    updateLocalStorage(result);
    setArc(Math.PI / (result.length / 2));
  };

  const changeHandler = (index: number, value: string) => {
    const result = teamMembers;
    const newResult = result.map((item, i) => {
      if (i === index) {
        return value;
      } else return item;
    });
    setTeamMembers(newResult);
    updateLocalStorage(newResult);
    // need drawWheelWithArg, drawWheel() not works since its geting old teamMembes array values
    drawWheelWithArg(newResult);
  };

  const addHandler = () => {
    const result = teamMembers;
    result.push(newMember);
    setTeamMembers(result);
    updateLocalStorage(result);
    setArc(Math.PI / (result.length / 2));
    setNewMember("");
  };

  const changeInputHandler = (event: any) => {
    setNewMember(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <canvas ref={canvasRef} width={500} height={500} />
          </Grid>
          <Grid item xs={12}>
            <button className={styles.spin} onClick={startSpin}>
              SPIN
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {teamMembers.map((one, index) => {
              return (
                <EditableTextField
                  changeHandler={(value) => changeHandler(index, value)}
                  value={one}
                  removeHandler={() => removeHandler(index)}
                />
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <input value={newMember} onChange={changeInputHandler} />
            <button onClick={addHandler}>Add</button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <WinnerCard winners={winners} cleanWinner={cleanWinner} />
      </Grid>
    </Grid>
  );
};
export default WheelDecider;
