import axios from "axios";
import { store } from "../AppProvider";
import { Store } from "../types/common/store";

export const baseRequest = axios.create({
  baseURL: "http://localhost:4000/", // TODO: change base url once in prod
  params: {},
  // `headers` are custom headers to be sent
  headers: { "X-Requested-With": "projectd" },
  timeout: 10000, // default is `0` (no timeout)
});

// Add a request interceptor
baseRequest.interceptors.request.use(
  function (config) {
    const state: Store = store.getState();
    const authToken = state.user.token;
    if (authToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${authToken}`,
      };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
baseRequest.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status == 403) {
      console.error("error 403");
    }
    return Promise.reject(error);
  }
);
