import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import createRootReducer from "./store/reducers";

const persistConfig: PersistConfig<any> = {
  key: "projectd",
  storage, // localstorage
  stateReconciler: autoMergeLevel2,
  whitelist: ["user"], // persist user state only here.. so refresh will keep user logged on..
};
const persistedReducer = persistReducer(persistConfig, createRootReducer());

// only use redux logger in dev env
const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const middlewares = [];
if (process.env.NODE_ENV === "development") {
  const { createLogger } = require("redux-logger"); // eslint-disable-line global-require
  const logger = createLogger({ duration: true });
  middlewares.push(logger);
}
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

export type Props = {
  children: JSX.Element;
};

const AppProvider: React.FC<Props> = ({ children }) => {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default AppProvider;
