import { useState, ChangeEventHandler } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";

export type EditableTextFieldProps = {
  value: string;
  removeHandler: () => void;
  changeHandler: (value: string) => void;
};

const EditableTextField: React.FC<EditableTextFieldProps> = ({
  value,
  removeHandler,
  changeHandler,
}) => {
  const [mode, setMode] = useState("view");
  const [currentValue, setValue] = useState(value);

  const saveItemHandler = () => {
    setMode("view");
    // re-draw with new item change
    changeHandler(currentValue);
  };

  const editItemHandler = () => {
    setMode("edit");
  }

  const removeItemHandler = () => {
    setMode("view");
    removeHandler();
  };

  const changeItemHandler = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <TextField
        id="standard-basic"
        variant="standard"
        value={currentValue}
        disabled={mode === "view"}
        onChange={changeItemHandler}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {mode === "view" && (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={editItemHandler}
                  edge="end"
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              )}
              {mode === "edit" && (
                <>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={saveItemHandler}
                    edge="end"
                    color="primary"
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={removeItemHandler}
                    edge="end"
                    color="error"
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
export default EditableTextField;
