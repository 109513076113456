import { useState, ChangeEventHandler } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export type Props = {
  winners: Array<string>;
  cleanWinner: () => void;
};

const WinnerCard: React.FC<Props> = ({ winners, cleanWinner }) => {
  const cleanWinnerHandler = () => {
    localStorage.removeItem("winners");
    cleanWinner();
  };
  console.log("winners", winners);
  return (
    <div>
      <Card>
        <CardContent>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Typography variant="h4" color="text.secondary">
                Winners
              </Typography>
            </Grid>
            {winners.map((w, i) => (
                <Grid item xs={12}>
                  <Typography variant="body2">{w}</Typography>
                </Grid>
            ))}
          </Grid>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={cleanWinnerHandler}>
            Clean
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
export default WinnerCard;
